<template>
  <div>
    <material-card
      color="general"
      :title="$t('Company.title')"
      :text="$t('Company.subtitle')"
    >
      <v-dialog
        v-model="dialog"
        max-width="700px"
      >
        <template #activator="{ on }">
          <v-btn
            v-if="role === 'SUPER_ADMIN'"
            color="zwonogreen"
            dark
            class="mb-2 z-btn-top-right"
            v-on="on"
          >
            {{ $t("Common.new_item") }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 z-card-title">
            <v-icon left>
              mdi-factory
            </v-icon>
            {{ $t("Company.subtitle") }}
            <v-spacer />
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="editCompanyForm"
              v-model="editedItem.isValid"
            >
              <v-container grid-list-md>
                <!-- Dropdown for API selection -->
                <v-layout wrap>
                  <v-layout wrap>
                    <v-flex>
                      <v-select
                        v-model="editedItem.search_address_api"
                        :items="['Geoapify', 'Planplus']"
                        :label="$t('Common.search_address_api')"
                      />
                    </v-flex>
                  
                    <v-flex>
                      <v-btn
                        color="blue"
                        @click="changeGetAddressModalVisiblity(true)"
                      >
                        {{ $t('Common.select_address_on_map') }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.company_name"
                        :label="$t('Company.company_name')"
                        :rules="[rules.required]"
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.country"
                        :label="$t('Common.country')"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.city"
                        :label="$t('Common.city')"
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.zip_code"
                        :label="$t('Common.zip')"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.address1"
                        :label="$t('Common.address')"
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.address2"
                        :label="$t('Common.address2')"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.vat"
                        :label="$t('Common.vat')"
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.reg_nr"
                        :label="$t('Vehicle.vehicle_registration')"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.company_www"
                        :label="$t('Company.www')"
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.company_email"
                        :label="$t('Company.email')"
                        :rules="[rules.email]"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.contact_person_name"
                        :label="$t('Company.contact_person_name')"
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.contact_person_phone"
                        :label="$t('Company.contact_person_phone')"
                        :rules="[rules.phoneNumber]"
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.contact_person_email"
                        :label="$t('Company.contact_person_email')"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.bank_name"
                        :label="$t('Company.bank_name')"
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.bank_account_nr"
                        :label="$t('Company.bank_account_nr')"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      md4
                    />
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.warehouse_lat"
                        :rules="[rules.required, rules.latitude]"
                        :label="$t('Company.warehouse_lat')"
                        type="number"
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.warehouse_lon"
                        :rules="[rules.required, rules.longitude]"
                        :label="$t('Company.warehouse_lon')"
                        type="number"
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.warehouse_email"
                        :label="$t('Company.warehouse_email')"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      md4
                    />
                    <v-flex>
                      <img
                        :key="imageAvatarKey"
                        :src="imageAvatar"
                        style="max-width: 100%"
                      >
                      <v-file-input
                        show-size
                        :label="$t('Common.avatar')"
                        filled
                        prepend-icon="mdi-camera"
                        accept="image/*"
                        @change="setImg"
                      />
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="zwonogray"
              class="z-hover-red-bg-color pr-5"
              @click="close"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t("Common.cancel") }}
            </v-btn>
            <v-btn
              color="zwonogreen"
              class="pr-5"
              :loading="isSaving"
              :disabled="!editedItem.isValid"
              @click="save"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ $t("Common.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div>
        <v-data-table
          :loading-text="$t('Common.loading-text')"
          :headers="headers"
          :items="companyList ? companyList : []"
          :options.sync="paginationOptions"
          :server-items-length="(companyListMeta?.itemCount) ? companyListMeta.itemCount : 0"
          :loading="paginationLoading"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          class="elevation-1"
        >
          <!-- change table header color(or other properties) -->
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="subheading font-weight-light text-general text--darken-3"
              v-text="header.text"
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip
              v-if="role === 'ADMIN' || role === 'SUPER_ADMIN'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-green-color mr-2"
                  v-bind="attrs"
                  @click="editItem(item)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.edit") }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="role === 'SUPER_ADMIN'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-hover-red-color"
                  v-bind="attrs"
                  @click="showDeleteModal(item)"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.delete") }}</span>
            </v-tooltip>
          </template>
          <template #no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </material-card>
    <default-popup
      :title="$t('Common.warning')"
      :text="$t('Common.delete_confirmation')"
      :confirm-text="$t('Common.delete_item')"
      :cancel-text="$t('Common.cancel')"
      :alert-text="alertTextDelete"
      :is-visible="isDeleteModalVisible"
      @is-visible="(value) => (isDeleteModalVisible = value)"
      @confirm-btn-click="() => deleteItem()"
    />
    <get-address-modal
      :is-visible="isGetAddressModalVisible"
      :is-saving="false"
      :search-address-api="editedItem.search_address_api ? editedItem.search_address_api : editedItem.search_address_api "
      :title="$t('Company.select_company_address')"
      @is-visible="(value) => changeGetAddressModalVisiblity(value)"
      @address-object="(item) => changeAddressObject(item)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import validationMixin from "../../components/helper/validationMixin";
import GetAddressModal from "../../components/modals/GetAddressModal.vue";

export default {
  components: {
    GetAddressModal
  },
  mixins: [validationMixin],
  data: () => ({
    dialog: false,
    isDeleteModalVisible: false,
    isGetAddressModalVisible: false,
    deleteModalItemId: 0,
    editedIndex: -1,
    editedItem: {
      isValid: true,
      search_address_api: 'Geoapify',
      company_name: "",
      warehouse_lat: "",
      warehouse_lon: "",
      contact_person_phone: "",
    },
    defaultItem: {
      isValid: true,
      search_address_api: 'Geoapify',
      company_name: "",
      warehouse_lat: "",
      warehouse_lon: "",
      contact_person_phone: "",
    },
    imageAvatar: "",
    imageAvatarKey: 0,
    role: "",
    alertTextEdit: "",
    alertTextDelete: "",
    isSaving: false,
    // table:
    paginationLoading: false,
    paginationOptions: {},
    oldSearchValue: '',
  }),

  computed: {
    ...mapGetters("company", ["companyList", "companyListMeta"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    headers() {
      return [
        { text: this.$t("Company.company_name"), value: "company_name" },
        { text: this.$t("Common.country"), value: "country" },
        { text: this.$t("Common.city"), value: "city" },
        {
          text: this.$t("Company.contact_person_name"),
          value: "contact_person_name",
        },
        {
          text: this.$t("Company.contact_person_phone"),
          value: "contact_person_phone",
        },
        {
          text: this.$t("Company.contact_person_email"),
          value: "contact_person_email",
        },
        { text: this.$t("Common.actions"), value: "actions", sortable: false },
      ];
    },
  },
  watch: {
    dialog(val) {
      this.alertTextEdit = "";
      if (val)  this.$nextTick(() => {this.$refs.editCompanyForm.validate()});
      else this.close()
    },
    paginationOptions: {
      handler(val) {
        if(val.search !== this.oldSearchValue) this.paginationOptions.page = 1
        this.oldSearchValue = val.search;
        this.initialize();
      },
      deep: true, // Watch changes deeply in the object
    },
  },

  // lifecycle hooks
  beforeDestroy() {
    this.clearCompanyState();
  },

  methods: {
    ...mapActions("company", [
      "getCompanyList",
      "createCompany",
      "updateCompany",
      "deleteCompany",
      "setAvatar",
      "getAvatar",
      "clearCompanyState",
    ]),
    async initialize() {
      this.paginationLoading = true;
      this.role = sessionStorage.getItem("role");
      this.paginationOptions.role = this.role;
      await this.getCompanyList(this.paginationOptions);
      this.paginationLoading = false;
    },

    async editItem(item) {
      this.editedIndex = this.companyList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.imageAvatar = await this.getAvatar(item.id);
    },

    showDeleteModal(item) {
      this.alertTextDelete = "";
      this.isDeleteModalVisible = true;
      this.deleteModalItemId = item?.id;
    },

    async deleteItem() {
      if (this.deleteModalItemId) {
        const isDeleted = await this.deleteCompany(this.deleteModalItemId);
        if (isDeleted) {
          this.isDeleteModalVisible = false;
        } else {
          this.alertTextDelete = this.$t("Company.alerts.error-delete");
        }
      }
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    async save() {
      this.isSaving = true;
      this.alertTextEdit = "";
      if (this.editedIndex > -1) {
        const isUpdated = await this.updateCompany(this.editedItem);
        if (isUpdated) {
          this.close();
        } else {
          this.alertTextEdit = this.$t("Company.alerts.error-edit");
        }
      } else {
        const isCreated = await this.createCompany(this.editedItem);
        if (isCreated) {
          this.close();
        } else {
          this.alertTextEdit = this.$t("Company.alerts.error-create");
        }
      }
      this.isSaving = false;
    },

    async setUserAvatar(image) {
      await this.setAvatar({image: image, companyId: this.editedItem.id});
    },

    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (res) => {
          resolve(res.target.result);
        };
        reader.onerror = (err) => reject(err); 
        reader.readAsDataURL(file);
      });
    },

    async setImg(file) {
      try {
        const image = await this.readFile(file);
        // For displaying image in view, we need only base64 encoded image!
        this.imageAvatar = image;
        // We should sent complete File to API!
        await this.setUserAvatar(file);
      } catch (err) {
        alert("There was a problem with changing picture!");
      }
    },

    changeGetAddressModalVisiblity(isVisible) {
      this.isGetAddressModalVisible = isVisible
    },

    containsNumber(str) {
      return /\d/.test(str);
    },

    changeAddressObject(addressObj) {
      this.editedItem.country = addressObj.country;
      this.editedItem.city = addressObj.city;
      this.editedItem.zip_code = addressObj.postal_code;
      this.editedItem.address1 = addressObj.address1 + ((!this.containsNumber(addressObj.address1) && addressObj.house_number) ? ` ${addressObj.house_number}` : '');
      this.editedItem.warehouse_lat = addressObj.lat;
      this.editedItem.warehouse_lon = addressObj.lon;
      
      this.changeGetAddressModalVisiblity(false);
    }
  },
};
</script>
